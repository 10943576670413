import React from "react";

import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { Container, Row, Col } from "react-bootstrap";
import YoutubeVideo from "components/elements/YoutubeVideo/YoutubeVideo";
import VideoPlayer  from "components/elements/VideoPlayer/VideoPlayer";
import "./VideosComponent.scss";

const VideosComponent = (props) => {
    const {
        subCategories
    } = props;

    return (
        <DashboardLayout>
            <div className="videos-wrapper">
                {subCategories.length ?
                    subCategories.map((subcategory, n) => (
                <div className="subcategory" key={n}>
                    <div className="section-title">
                        <h1>{subcategory.name}</h1>
                    </div>
                        <Row>
                            {subcategory.videos.length ? (
                            subcategory.videos.map((video, i) => (
                            <Col xs={12} lg={6} xxl={4}>
                                <VideoPlayer
                                    key={video.videoId}
                                    title={video.title}
                                    description={video.description}
                                    thumbnail={video.thumbnail}
                                    type={video.type}
                                    src={video.url}
                                    videoId={video.videoId}
                                />
                            </Col>
                            ))
                            ) : (<div className="empty">No hay vídeos para mostrar.</div>)
                            }
                        </Row>
                </div>)) : (<div className="empty">No hay subcategorías para mostrar.</div>) }
            </div>
        </DashboardLayout>
    );
};

export default VideosComponent;
