import React, { useEffect, useState } from "react";
import VideosComponent from "../components/VideosComponent";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { getLocalVideos, getCategoryService } from "services/videos";

const Videos = (props) => {
    const [videos, setVideos] = useState([]);
    const history = useHistory();
    const [category, setCategory] = useState({});
    const [subCategories, setSubCategories] = useState([]);
    const [videosFetching, setVideosFetching] = useState(false);
    const [isCreateVideoModalOpen, setIsCreateVideoModalOpen] = useState(false);
    const [isManageVideoModalOpen, setIsManageVideoModalOpen] = useState(false);
    const [submitVideoLoading, setSubmitVideoLoading] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState({videoData:{id:'',snippet:{title:'',description:''}}});

    const { name } = useParams();
    const { subcategory } = useParams();

    useEffect(() => {
        getVideos();
    }, [name, subcategory]);


    useEffect(() => {
        setVideosFetching(false);
    }, [subCategories]);

    const getVideos = () => {
        setVideosFetching(true);

        try {


            let categoryFromUrl = getCategoryService(name);

            //If not subcategories, then the category is also the subcategory.

            if(categoryFromUrl.subcategories === undefined) {
                categoryFromUrl.subcategories = [
                    categoryFromUrl
                ]
            }

            console.log("SUBCATEGORY", subcategory);

            if(typeof subcategory !== 'undefined') {

                console.log("SUBCATEGORY SOMETHING", subcategory)

                let filtered = categoryFromUrl.subcategories.filter(s => s.name.includes(subcategory))

                console.log("SUBCATEGORY FILTERED", subcategory)

                setSubCategories(filtered);
            } else {

                setSubCategories(categoryFromUrl.subcategories)
            }


        } catch (err) {
            console.log(err);
            setVideosFetching(false);

        }

    };


    return (
        <VideosComponent
            videos={videos}
            videosFetching={videosFetching}
            subCategories={subCategories}
            setVideosFetching={setVideosFetching}
            isManageVideoModalOpen={isManageVideoModalOpen}
            setIsManageVideoModalOpen={setIsManageVideoModalOpen}
            submitVideoLoading={submitVideoLoading} 
            setSubmitVideoLoading={setSubmitVideoLoading}
            selectedVideo={selectedVideo}
            setSelectedVideo={setSelectedVideo}
            setIsCreateVideoModalOpen={setIsCreateVideoModalOpen}
        />
    );
};

export default Videos;
