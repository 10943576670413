import React, { useState } from "react";
import { Player, Video, DefaultUi } from '@vime/react';
import "./VideoPlayer.scss";
import { getUserVideosPermission } from "services/videos";
import { startCase } from "lodash";

const VideoPlayer = (props) => {
    const {src, title, description, videoId, thumbnail} = props;

    const [videoUrl, setVideoUrl] = useState(src);
    const [permissionLayer, setPermissionLayer] = useState(true);

    const backgroundStyle = 'url("'+thumbnail+'")';
    const loadVideo = () => {
        (async () => {
            try {
                const response = await getUserVideosPermission({'source': 's3', 'videoId': videoId});

                // console.log("VIDEO URL", response);
                setVideoUrl(response.url);
                setPermissionLayer(false);

            } catch (e) {
                // console.log("VIDEO ERROR", videoUrl);
            }
        })();
    }

    return (
        <div className="video-container">

            <div className="video-player">
                { permissionLayer ? <div className="load-permissions" style={{background:backgroundStyle}} onClick={loadVideo}></div> : null }
                <Player autoplay muted controls>
                    <Video cross-origin>
                        <source data-src={videoUrl} type="video/mp4" />
                    </Video>
                    <DefaultUi noSettings>

                    </DefaultUi>
                </Player>
            </div>
            <div className="information">
                <h3 className="title">{title}</h3>
            </div>
            <div className="information">
                <h3 className="description">{description}</h3>
            </div>
        </div>
    );
};

export default VideoPlayer;
