import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export function getLocalVideos() {

    return JSON.parse(localStorage.getItem("localVideos"));

};

export function setLocalVideos(data) {
    if(localStorage.getItem("localVideos") !== null) {
        localStorage.removeItem("localVideos");
    } 

    localStorage.setItem("localVideos", JSON.stringify(data));
};

export function getCategoryService(name) {

    let data = getLocalVideos();

    if(data.categories == null) return null;

    return data.categories.find(category => category.name == name);
}


export const getUserVideosService = async () => {
    try {
        let response = await customFetch(`${endpoints.content}/videos`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getUserVideosPermission = async (data) => {
    try {
        let response = await customFetch(`${endpoints.content}/see-file`, {
            method: "POST",
            token: getUserToken(),
            bodyReq: data
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

//https://api.dev.netchain.pitagorinesgroup.com/content/see-file

//https://api.dev.netchain.pitagorinesgroup.com/content/videos 