import React from 'react';
import jwt_decode from "jwt-decode";
import moment from "moment";
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, user, role, ...rest }) => {

    let isLogged;
    try {
        let userObject = jwt_decode(user.token);
        isLogged = userObject.exp > moment().unix();
    } catch(e) {
        isLogged = false;
    }

    //const isLogged = !_.isEmpty(user);
    const userRole = user && user.role;
    const roleMatch = role ? (userRole === role) : true;

    return (
        <Route {...rest} render={props => (
            isLogged && roleMatch ?
                <Component {...props} />
                : <Redirect to="/login" />
        )} />
    );
};

const mapStateToProps = (store) => ({
    user: store.userReducer.user
});

export default connect(mapStateToProps)(PrivateRoute);