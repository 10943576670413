import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import AuthLayout from "layouts/AuthLayout/AuthLayout";
import Button from "components/elements/Button/Button";
import MaterialInput from "components/elements/MaterialInput/MaterialInput";
import "./LoginComponent.scss";

const LoginComponent = (props) => {
    const { loading, error, socket, QRCode, socketId } = props;

    return (
        <AuthLayout>
            <div className="login-component">        
                    <p class="login-title">Inicia sesión en Sicomoro Netchain</p>
                    <p class="login-subtitle">Para iniciar sesión, escanea el código QR desde la App de Netchain</p>
                    <div className="qr-container">
                    {( socketId.length == 0 ? <div className="waiting-qr"></div> : <QRCode text={socketId} size="200"/> )}
                </div>
            </div>
        </AuthLayout>
    );
};

export default LoginComponent;
