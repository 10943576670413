import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateUser } from "redux/actions/userActions";
import { Link, withRouter } from "react-router-dom";
import { getLocalVideos } from "services/videos";
import MenuAccordion  from "components/elements/MenuAccordion/MenuAccordion";
import OutsideClickHandler from "react-outside-click-handler";
import { FaChevronDown, FaSignOutAlt, FaExternalLinkAlt } from "react-icons/fa";
import { FiUsers, FiBook } from "react-icons/fi";
import { HiOutlineTicket } from "react-icons/hi";
import { AiOutlineCreditCard, AiFillDashboard, AiOutlineYoutube } from "react-icons/ai";
import { RiMailSendLine } from "react-icons/ri";
import { MdCardMembership } from "react-icons/md";
import { BsChat, BsBell } from "react-icons/bs";

import "./DashboardLayout.scss";

const DashboardLayout = (props) => {
    const { user, updateUser } = props;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    let videos = getLocalVideos();
    let userRoutes = [];

    if(videos !== null)

    videos.categories.forEach(category => {
        userRoutes.push({
            label: category.name,
            routeLink: "/category/" + encodeURI(category.name),
        })
    });

    const routes = userRoutes;

    const {
        children,
        location: { pathname },
    } = props;
    const currentPath = pathname;

    const username = user.name;
    const userphoto = user.urlProfileImage;

    const logout = () => {
        updateUser({});
        localStorage.clear();
    };

    return (
        <div className="dashboard-layout">
            <div className="sidebar">
                <div className="logo">
                    <img src={require("assets/images/sicomoro-logo.png")} alt="logo-full" />
                </div>

                <MenuAccordion data={videos}/>

            </div>

            <div className="content">
                <div className="top-bar">
                    <div className="logged-user-info">
                        <div className="option" onClick={() => logout()}>
                            <span>Cerrar sesión </span>
                            <FaSignOutAlt /> 
                        </div>
                    </div>
                </div>
                <div className="content-children">{children}</div>
            </div>
        </div>
    );
};

const mapStateToProps = (store) => ({
    user: store.userReducer.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardLayout));
